
import AbiToken from "../abi/AbiToken.json";
export const Global ={
    URL :'https://coredrop.difi.market'
}
export const walletProvider = {
    METAMASK: 'metaMask',
    TRUSTWALLET: 'trustWallet',
    SAFEPAL: 'safePal',
    WALLET_CONNECT: 'walletConnect'
};

//TESTNET
export const RPC_NODE_TESTNET={
    http:"https://rpc-core.icecreamswap.com/",
    wss:"wss://rpc-core.icecreamswap.com/",
}

export const RPC_NODE={
    http:"https://rpc-core.icecreamswap.com/",
    wss:"wss://rpc-core.icecreamswap.com/"
}

export const REF_AIRDROP={
   address:'0x0000000000000000000000000000000000000000',
   pool:'0xE6EFcBd4Fbe83aA2c78297dD751102d7E11fE6e7'
}


export const TOKEN_STAKE ={
    address:'0x2C291980Eeabd3571cEE64e76e8c481E773919Ac',
    abi: AbiToken
}
